import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import externalLinks from 'utils/externalLinks';
import Layout from 'components/Layout';
import SectionsWrapper from 'components/SectionsWrapper';
import { useLayoutContext } from 'context/LayoutContext';
import ReadyToTrade from 'components/ReadyToTrade';
import Section from 'components/Section';
import Headline from 'components/Headline';
import SEO from 'components/SEO';

const ContactUsWrapper = styled(SectionsWrapper)`
  margin-top: 6.5625rem;

  ${ifProp('$showAlert', css`
    margin-top: 9.5625rem;
  `)}

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 10.0625rem;

    ${ifProp('$showAlert', css`
      margin-top: 13.0625rem;
   `)}
  }
`;

const HeadlineSection = styled(Section)`
  ${Headline} {
    text-align: left;
    padding-bottom: 1rem;
  }
`;

const ContactUsSection = styled(Section)`
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
`;

const ContactUsInfo = styled.div`
  margin-bottom: 1rem;

  p {
    margin: 0;
  }
`;

const StyledHeader = styled.p`
  font-size: 1.125rem;
  text-transform: uppercase;

  &&& {
    font-weight: 700;
  }
`;

function ContactUs() {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  return (
    <Layout>
      <SEO title='Contact Us' />
      <ContactUsWrapper noGutters $showAlert={showAlert}>
        <HeadlineSection withPadding>
          <Headline>
            Contact Us
          </Headline>
          <hr />
        </HeadlineSection>
        <ContactUsSection withPadding>
          <ContactUsInfo>
            <StyledHeader>Support (Australia)</StyledHeader>
            <p>For all trading, account and technical help, please choose from one of the following support options:</p>
          </ContactUsInfo>
          <ContactUsInfo>
            <p>
              <span className='font-weight-bold'>Email:{' '}</span>
              <a href='mailto:support@tastytrade.au'>support@tastytrade.au</a>
            </p>
          </ContactUsInfo>
          <ContactUsInfo>
            <p>
              <span className='font-weight-bold'>Phone:{' '}</span>
              +61 02 8317 1402
            </p>
          </ContactUsInfo>
          <ContactUsInfo>
            <p>
              <span className='font-weight-bold'>Office Address</span>
            </p>
            <p>L 17, 123 Pitt St</p>
            <p>Sydney NSW 2000</p>
          </ContactUsInfo>
          <ContactUsInfo>
            <StyledHeader>Support (USA)</StyledHeader>
            <p>Alternatively, to contact the US Support Team, please choose from one of the following support options:</p>
          </ContactUsInfo>
          <ContactUsInfo>
            <p>
              <span className='font-weight-bold'>Toll-Free Number{' '}</span>
              (7:00 AM CST - 5:00 PM CST, Monday-Friday)</p>
            <p>888-247-1963</p>
          </ContactUsInfo>
          <ContactUsInfo>
            <p>
              <span className='font-weight-bold'>Local and International{' '}</span>
              (7:00 AM CST - 5:00 PM CST, Monday-Friday)
            </p>
            <p>312-724-7075</p>
          </ContactUsInfo>
          <ContactUsInfo>
            <p>
              <span className='font-weight-bold'>Fax:{' '}</span>
              312-724-7364
            </p>
          </ContactUsInfo>
        </ContactUsSection>
        <ReadyToTrade />
      </ContactUsWrapper>
    </Layout>
  )
};

export default ContactUs;
